import type { Question } from 'types';

const errors = {
	// auth
	REFRESH_TOKEN: 'There was an error refreshing your token',
	AUTHORIZE_NO_CREDENTIALS: 'There was an error fetching user info',
	FAULTY_SIGNIN: 'An error occurred while signing you in',
	// navigation
	PAGE_NOT_FOUND: '404 Page Not Found',
	UNEXPECTED_ERROR: 'An unexpected error occurred',
	UNAUTHORIZED: 'You must be signed in to view that page',
	UNAUTHORIZED_EXTERNAL:
		'Please reach out to your account team if you would like an invitation to complete an Alteryx Analytics Transformation Assessment',
	// form errors
	X_IS_REQUIRED: (question: string) => `${question} is required`,
	FAILED_TO_POST_ANSWERS: 'An error occurred while submitting your answers',
	ERROR_POSTING: 'An error occurred while submitting your info',
	ERROR_POSTING_ACCOUNT: 'An error occurred while submitting account info',
	FIX_BEFORE_SUBMITTING: 'Please fix all errors before submitting',
	ANSWER_REQUIRED_QUESTIONS: 'Please answer the required questions in red',
	IDK_THRESHOLD_EXCEEDED: `You have answered 'I don't know' too many times to proceed.
		Please go back and change your answers if you wish to continue.`,
	FIELD_REQUIRED: 'This field is required',
	INVALID_SFDC_ID: 'SFDC IDs must contain 18 alphanumeric characters',
	// assessment
	FAILED_TO_LOAD_ASSESSMENT:
		'An error occurred while loading questions; returning you to the landing page',
	UNSUPPORTED_QUESTION_TYPE: (c: Question) =>
		`Unsupported question type: ${c.inputType} (id: ${c.id})`,
	// report
	FAILED_TO_RETRIEVE_REPORT: `There was an error retrieving your report`,
};

const successes = {
	REFRESH_TOKEN: 'Token successfully refreshed',
	LOGGED_IN: 'Successfully signed in',
};

const info = {
	AUTHORIZING:
		'If you are not redirected momentarily, click the button below to navigate home',
};

export { errors, successes, info };
